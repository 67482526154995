body, html {
    margin: 0;
    padding: 0;
    min-height: 100%; /* Change from height to min-height */
    overflow-y: auto; /* Ensure vertical scrolling is enabled */
    font-family: 'Inter', sans-serif; /* Updated body font */
    background: #f6f5f1; /* Updated to match Anthropic's main background */
}

.landing-page {
    color: #1a1a1a; /* Darker text color */
    text-align: center;
    padding: 20px;
    min-height: 100vh; /* Ensure it takes at least the full viewport height */
    box-sizing: border-box;
}

.header {
    background-color: #faf9f7; /* Updated to match Anthropic's tan header */
    padding: 80px 20px;
    border-bottom: 1px solid #e6e3dd;
    box-shadow: none;
}

.header h1 {
    margin: 0;
    font-size: 4em; /* Larger font */
    font-weight: 700;
    color: #1a1a1a; /* Darker text instead of tan */
    font-family: 'Space Grotesk', sans-serif; /* Updated heading font */
    letter-spacing: -0.02em; /* Tighter letter spacing for headings */
}

.header p {
    margin: 10px 0 0;
    font-size: 1.5em;
    color: #666; /* Softer subtitle color */
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}

.content {
    margin: 50px 0;
}

.introduction, .projects, .approach {
    margin-bottom: 50px;
    text-align: left;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    background-color: #faf9f7; /* Anthropic's card background color */
    padding: 30px;
    border-radius: 10px;
    box-shadow: none; /* Removed shadow */
    border: 1px solid #e6e3dd; /* Subtle border */
}

.introduction h2, .projects h2, .approach h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
    font-weight: 700;
    color: #1a1a1a; /* Darker text instead of tan */
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: -0.02em;
}

.projects h3, .approach-item h3 {
    font-size: 1.8em;
    margin-top: 20px;
    font-weight: 600;
    color: #666; /* Softer subtitle color */
    font-family: 'Space Grotesk', sans-serif;
    letter-spacing: -0.01em;
}

.projects ul {
    list-style-type: none;
    padding: 0;
}

.projects li {
    margin: 10px 0;
    display: flex;
    align-items: flex-start; /* Change from center to flex-start */
    gap: 10px; /* Add consistent gap instead of margin-right */
    padding: 5px 0; /* Add some vertical padding */
}

.projects li::before {
    content: '✔️';
    flex-shrink: 0; /* Prevent icon from shrinking */
    display: inline-block;
    width: 24px;
    height: 24px;
    min-width: 24px; /* Add min-width to prevent shrinking */
    background-color: #27ae60; /* Green */
    color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 24px;
}

/* Ensure text wraps properly on mobile */
.projects li span {
    flex: 1;
    word-break: break-word; /* Allow text to wrap naturally */
}

.projects li.not-mobile-friendly::before {
    content: '❌';
    background-color: #e74c3c; /* Red */
}

.approach-item {
    background-color: #fff; /* White background for nested items */
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: none; /* Removed shadow */
    border: 1px solid #e6e3dd; /* Subtle border */
}

.footer {
    background-color: #000000; /* Black background */
    padding: 30px;
    border-top: 1px solid #1a1a1a; /* Darker border for footer */
    font-size: 1em;
    color: #ffffff; /* White text */
    box-shadow: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer p {
    margin-bottom: 10px;
    color: #ffffff; /* White text */
}

.footer a {
    margin: 0 10px;
    text-decoration: none;
    display: inline-block;
    color: #ffffff; /* White text */
}

.footer-icons {
    display: flex;
    justify-content: center;
    align-items: center;
}

.instagram-icon, .linkedin-icon {
    color: #ffffff; /* White icons */
    transition: color 0.3s ease;
}

.instagram-icon:hover, .linkedin-icon:hover {
    color: #cccccc; /* Light grey on hover */
}

.project-image {
    max-width: 100%;
    height: auto;
    margin-top: 20px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.project-image:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
}

.download-section {
    margin-top: 30px;
    padding: 20px 20px 60px;
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e6e3dd;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Space Grotesk', sans-serif;
    text-align: left; /* Ensure text stays left-aligned */
}

.download-links {
    display: flex;
    justify-content: center; /* Center the button groups */
    gap: 20px;
    margin-top: 15px;
}

.button-group {
    display: flex;
    flex-direction: column;
    gap: 8px; /* Reduced gap between download and try buttons */
}

.download-link {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    background-color: #1a1a1a;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 330px; /* Further reduced width to fit better in desktop view */
}

.try-link {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background-color: #27ae60;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    width: 330px; /* Matching download-link width */
}

/* Mobile styles */
@media (max-width: 900px) {
    .download-links {
        flex-direction: column;
        align-items: center;
        gap: 30px; /* Increased from 20px to 30px */
    }
    
    .button-group {
        width: 110%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    
    .download-link,
    .try-link {
        width: 100%;
    }
    
    .download-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 80px;
        text-align: center;
    }
    
    .download-section h2,
    .download-section p {
        text-align: left;
        align-self: flex-start;
        width: 100%;
    }

    /* Increased margin between button groups */
    .button-group + .button-group {
        margin-top: 30px; /* Increased from 20px to 30px */
    }
}

.image-caption {
    font-size: 0.9em;
    color: #666;
    margin-top: 8px;
    margin-bottom: 16px;
    font-style: italic;
}

/* Add smooth font rendering */
* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
