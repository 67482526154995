/* frontend/src/App.css */

/* General Styling */
body {
  margin: 0;
  overflow: hidden;
  font-family: Arial, sans-serif;
}

.app {
  display: flex; /* Enable Flexbox */
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

/* Sidebar Styling */
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;
  height: 100vh;
  overflow-y: auto;
}

/* Sidebar Header */
.sidebar h2 {
  margin: 0 0 20px 0;
  color: #2c3e50;
  font-size: 1.5rem;
  font-weight: 500;
  padding-bottom: 15px;
  border-bottom: 2px solid #e0e0e0;
}

/* Icon Category Container */
.icon-category {
  margin-bottom: 20px; /* Space between categories */
}

/* Category Title */
.category-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

/* Icons List Container */
.icons-list {
  display: flex;
  flex-direction: column;
}

/* Draggable Icon Container */
.draggable-icon {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Space between different icons */
  cursor: pointer;
}

.draggable-icon img {
  width: 50px; /* Icon width */
  height: 50px; /* Icon height */
  object-fit: contain;
  margin-right: 10px; /* Space between icon and name */
}

.icon-name {
  font-size: 14px;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px; /* Adjust as needed */
}

/* Optional: Highlight Category on Hover */
.icon-category:hover {
  background-color: #eaeaea;
  border-radius: 4px;
}

/* Icon Palette Styling */
.icon-palette {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  flex-grow: 1;
}

/* Buttons Container in Sidebar */
.button-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

/* Export Buttons Container */
.export-buttons {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

/* Button Styling */
.save-button,
.load-button,
.toggle-button,
.export-button {
  padding: 10px;
  margin-bottom: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

/* Adjusted Export Button Styling */
.export-button {
  flex: 1;
  margin-bottom: 0;
}

.save-button:hover,
.load-button:hover,
.toggle-button:hover,
.export-button:hover {
  background-color: #0056b3;
}

/* Selected Icon Indicator */
.selected-icon {
  padding: 8px 12px;
  background-color: rgba(0, 123, 255, 0.8);
  color: white;
  border-radius: 4px;
  margin-top: 10px;
}

/* Icon State Display for Debugging (Remove in Production) */
.icon-state {
  position: absolute;
  bottom: 10px;
  left: 270px; /* Adjusted for sidebar width */
  background: rgba(255, 255, 255, 0.8);
  padding: 10px;
  border-radius: 4px;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
}

.icon-state h3 {
  margin-top: 0;
}

.icon-state ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.icon-state li {
  margin-bottom: 5px;
}

/* Icon Label Styling */
.text-label {
  font-size: 12px;
  color: black;
  background: rgba(255, 255, 255, 0.8);
  padding: 2px 4px;
  border-radius: 4px;
  text-align: center;
  pointer-events: none;
}

/* Radial Menu Styling */
.radial-menu {
  position: relative;
  width: 120px;
  height: 120px;
}

.radial-button {
  position: absolute;
  width: 40px;
  height: 40px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  transform-origin: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  text-align: center;
  line-height: normal;
  transition: background-color 0.3s;
  opacity: 0;
  transform: scale(0);
  animation: radial-menu-animation 0.3s forwards;
  animation-delay: calc(var(--index) * 0.05s);
}

.radial-button:hover {
  background-color: #0056b3;
}

/* Position buttons around the circle without rotating text */
.radial-button {
  top: 50%;
  left: 50%;
  transform: rotate(var(--angle)) translate(0) rotate(calc(-1 * var(--angle)));
}

@keyframes radial-menu-animation {
  to {
    transform: rotate(var(--angle)) translate(60px) rotate(calc(-1 * var(--angle)));
    opacity: 1;
  }
}

/* Modal Styling */
.modal {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 400px;
  width: 90%;
  margin: auto;
  position: relative;
  outline: none;
  max-height: 80vh; /* Added to limit height */
  overflow-y: auto;  /* Added to enable scrolling */
}

.modal-overlay {
  background-color: rgba(0, 0, 0, 0.75);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Content Styling */
.modal h2 {
  margin-top: 0;
}

.modal form {
  display: flex;
  flex-direction: column;
}

.modal label {
  margin-bottom: 10px;
}

.modal select,
.modal input[type="text"],
.modal input[type="color"] {
  padding: 5px;
  margin-top: 5px;
}

.modal button {
  padding: 8px 12px;
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal button:hover {
  background-color: #0056b3;
}

.modal button[type="button"] {
  background-color: #6c757d;
}

.modal button[type="button"]:hover {
  background-color: #5a6268;
}

/* Scrollbar Styling for Icon State Display */
.icon-state::-webkit-scrollbar {
  width: 6px;
}

.icon-state::-webkit-scrollbar-thumb {
  background-color: #cccccc;
  border-radius: 3px;
}

.icon-state::-webkit-scrollbar-thumb:hover {
  background-color: #aaaaaa;
}

/* Tooltip Styling */
img[title],
.text-label {
  position: relative;
}

img[title]:hover::after,
.text-label:hover::after {
  content: attr(title);
  position: absolute;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 8px;
  border-radius: 4px;
  white-space: nowrap;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 1000;
}

/* Text Wrapping for 3D Text Labels */
.text-label {
  max-width: 20ch; /* Limit to 20 characters */
  overflow-wrap: break-word;
}

/* View Toggle Button Styling */
.view-toggle-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.view-toggle-button:hover {
  background-color: #0056b3;
}

/* Connection Menu Styling */
.connection-menu {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.connection-button {
  padding: 5px 10px;
  margin: 2px 0;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.connection-button:hover {
  background-color: #0056b3;
}

/* Workflow Panel Styling */
.workflow-panel {
  margin-top: 20px;
}

.workflow-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 5px;
}

.workflow-button {
  padding: 8px 12px;
  width: 100%;
  margin-bottom: 10px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.workflow-button:hover {
  background-color: #218838;
}

.workflow-list {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.workflow-item {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}

.workflow-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
  background: white;
}

.workflow-item-header span {
  font-weight: 500;
  color: #1a202c;
  font-size: 0.95rem;
}

.workflow-actions {
  display: flex;
  gap: 8px;
  margin-left: auto; /* This ensures the actions stay right-aligned */
  min-width: 120px; /* This ensures consistent width for the action buttons area */
  justify-content: flex-end; /* This ensures the buttons are right-aligned within their container */
}

.workflow-action-button {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  color: #4a5568;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  padding: 0; /* Remove padding to ensure consistent button size */
  flex-shrink: 0; /* Prevent buttons from shrinking */
}

.workflow-action-button:hover {
  background-color: #f7fafc;
  border-color: #4299e1;
  color: #4299e1;
}

.workflow-action-button svg {
  width: 16px;
  height: 16px;
}

/* Presentation Controls Styling */
.presentation-controls {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 15px;
  border-radius: 8px;
}

.presentation-controls button {
  padding: 8px 12px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.presentation-controls button:hover {
  background-color: #0056b3;
}

.presentation-controls span {
  font-weight: bold;
}

/* Top Bar Styling */
.top-bar {
  position: absolute;
  top: 0;
  right: 0;
  left: 250px;
  height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  padding: 0 16px;
  z-index: 100;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  justify-content: space-between; /* Add this to create space between button groups */
}

/* Button Groups */
.button-group {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
}

/* Add this new style for the workflow buttons group */
.button-group.workflow-buttons {
  margin-left: auto; /* This will push the workflow buttons to the right */
}

/* Remove any flex-direction: column that might be inherited */
.top-bar > *,
.button-group > * {
  flex-direction: row !important;
}

/* Individual Buttons */
.top-bar-button {
  height: 36px;
  padding: 0 12px;
  background-color: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  color: #2c3e50;
  font-size: 0.9rem;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.top-bar-button.primary {
  background-color: #2196f3;
  color: white;
  border: none;
}

/* Hide scrollbar but keep functionality */
.top-bar::-webkit-scrollbar {
  display: none;
}

.top-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

/* Main content adjustments */
.main-content {
  position: absolute;
  top: 60px; /* Match top bar height */
  left: 250px; /* Match sidebar width */
  right: 0;
  bottom: 0;
  overflow: hidden;
}

/* Hidden file input */
.hidden-file-input {
  display: none;
}

/* Workflow panel styling */
.workflows-panel {
  position: absolute;
  top: 70px;
  right: 20px;
  background: white;
  border-radius: 12px;
  width: 340px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  z-index: 90;
}

.workflows-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 20px;
  border-bottom: 1px solid #e0e0e0;
  background-color: #f8fafc;
  border-radius: 12px 12px 0 0;
}

.workflows-header h3 {
  margin: 0;
  font-size: 1.1rem;
  color: #1a202c;
  font-weight: 600;
  letter-spacing: -0.01em;
}

.workflows-header button {
  padding: 4px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  color: #64748b;
  border-radius: 6px;
  font-size: 1.2rem;
  transition: all 0.2s ease;
}

.workflows-header button:hover {
  background-color: #e2e8f0;
  color: #1e293b;
}

.workflows-list {
  max-height: 400px;
  overflow-y: auto;
  padding: 16px;
}

.workflow-item {
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  margin-bottom: 12px;
  transition: all 0.2s ease;
}

.workflow-item:hover {
  border-color: #2196f3;
  box-shadow: 0 2px 8px rgba(33, 150, 243, 0.1);
}

.workflow-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 16px;
}

.workflow-item-header span {
  font-weight: 500;
  color: #1a202c;
  font-size: 0.95rem;
}

.workflow-actions {
  display: flex;
  gap: 6px;
}

.workflow-action-button {
  padding: 6px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  cursor: pointer;
  color: #64748b;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
}

.workflow-action-button:hover {
  background-color: #f8fafc;
  border-color: #2196f3;
  color: #2196f3;
}

.workflow-description {
  padding: 12px 16px;
  color: #64748b;
  font-size: 0.9rem;
  background: #f8fafc;
  border-top: 1px solid #e2e8f0;
  border-radius: 0 0 8px 8px;
}

/* Empty state styling */
.no-workflows {
  padding: 24px;
  text-align: center;
  color: #64748b;
}

/* Scrollbar styling */
.workflows-list::-webkit-scrollbar {
  width: 6px;
}

.workflows-list::-webkit-scrollbar-track {
  background: transparent;
}

.workflows-list::-webkit-scrollbar-thumb {
  background: #cbd5e0;
  border-radius: 3px;
}

.workflows-list::-webkit-scrollbar-thumb:hover {
  background: #94a3b8;
}

/* Ensure export buttons stay in line */
.export-buttons {
  display: flex;
  flex-direction: row !important;
  gap: 8px;
  margin: 0;
}

/* Reset any conflicting styles */
.button-container {
  flex-direction: row !important;
  margin: 0;
  gap: 8px;
}

