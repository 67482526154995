/* WorkflowModals.css */

:root {
  --primary-color: #007bff;
  --secondary-color: #6c757d;
  --success-color: #28a745;
  --danger-color: #dc3545;
  --light-gray: #f8f9fa;
  --medium-gray: #e9ecef;
  --dark-gray: #495057;
  --white: #ffffff;
  --box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  --transition: all 0.3s ease;
}

.workflow-modal {
  background-color: var(--white);
  border-radius: 8px;
  box-shadow: var(--box-shadow);
  max-width: 600px;
  width: 90%;
  margin: 2rem auto;
  padding: 2rem;
  outline: none;
  max-height: 90vh;
  overflow-y: auto;
  font-family: 'Arial', sans-serif;
}

.workflow-modal-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.workflow-modal h2 {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: var(--dark-gray);
  border-bottom: 2px solid var(--medium-gray);
  padding-bottom: 0.5rem;
}

.workflow-modal form {
  display: flex;
  flex-direction: column;
}

.workflow-modal label {
  margin-bottom: 1rem;
  font-weight: 600;
  color: var(--dark-gray);
}

.workflow-modal input[type="text"],
.workflow-modal select {
  width: 100%;
  padding: 0.75rem;
  margin-top: 0.25rem;
  border: 1px solid var(--medium-gray);
  border-radius: 4px;
  font-size: 1rem;
  transition: var(--transition);
}

.workflow-modal input[type="text"]:focus,
.workflow-modal select:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.workflow-steps-manager {
  margin-top: 2rem;
}

.workflow-steps-manager h3 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  color: var(--dark-gray);
}

.steps-list {
  list-style-type: none;
  padding-left: 0;
  margin-bottom: 1.5rem;
}

.steps-list-item {
  display: flex;
  align-items: center;
  padding: 5px; /* Overridden to 5px from 1rem */
  background-color: var(--light-gray);
  border: 1px solid var(--medium-gray);
  border-bottom: 1px solid #ddd; /* Added from later definition */
  border-radius: 4px;
  margin-bottom: 0.75rem;
  transition: var(--transition);
}

.steps-list-item:hover {
  background-color: var(--medium-gray);
  cursor: grab;
}

.steps-list-item.dragging {
  opacity: 0.5;
  box-shadow: var(--box-shadow);
}

.steps-list-item.target-step {
  background-color: #e0f7fa; /* Light blue background */
  border: 1px dashed #00796b; /* Dashed border */
}

.add-parallel-mode .steps-list-item:not(.target-step) {
  opacity: 0.5; /* Dim other steps */
}

.steps-list-item.hovered {
  background-color: #f1f8e9; /* Light green background */
}

.step-number {
  font-weight: 600;
  margin-right: 10px; /* Overridden to 10px from 1rem */
  color: var(--primary-color);
}

.steps-list-item .step-number {
  margin-right: 10px;
}

.step-name {
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.steps-list-item .step-name {
  flex-grow: 1;
}

.step-icon {
  margin-right: 0.75rem;
  font-size: 1.2rem;
  color: var(--primary-color);
}

.step-actions {
  display: flex;
  align-items: center;
  gap: 5px;
}

.steps-list-item .step-actions {
  display: flex;
  align-items: center;
}

.steps-list-item .step-actions button {
  margin-left: 5px;
  background: none;
  border: none;
  cursor: pointer;
}

.steps-list-item .step-actions button:hover {
  color: #00796b;
}

.remove-step-button {
  background: none;
  border: none;
  color: #f44336; /* Overridden from var(--danger-color) */
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem;
  transition: var(--transition);
  border-radius: 50%;
}

.remove-step-button:hover {
  color: #d32f2f;
}

.add-step-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;
  margin-top: 10px; /* Added from later definition */
}

.select-group {
  display: flex;
  gap: 1rem;
}

.add-step-container .select-group {
  display: flex;
  gap: 10px;
  margin-bottom: 5px;
}

.select-group select {
  flex: 1;
}

.add-step-container select {
  padding: 5px;
}

.add-step-button {
  padding: 5px 10px; /* Overridden from 0.75rem 1rem */
  background-color: var(--success-color);
  color: var(--white);
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: var(--transition);
}

.add-step-button:hover {
  background-color: #218838;
}

.modal-buttons {
  margin-top: 20px; /* Overridden from 2rem */
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Overridden from 1rem */
}

.primary-button {
  padding: 8px 12px; /* Overridden from 0.75rem 1.5rem */
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: var(--transition);
  background-color: #2196f3;
  color: white;
}

.primary-button:hover {
  background-color: #1976d2;
}

.secondary-button {
  padding: 8px 12px; /* Overridden from 0.75rem 1.5rem */
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: var(--transition);
  background-color: #e0e0e0;
  color: #333;
}

.secondary-button:hover {
  background-color: #bdbdbd;
}

.parallel-steps {
  border-left: 2px dashed #ccc;
  margin-left: 10px;
  padding-left: 10px;
}

.add-parallel-step-button {
  background: none;
  border: none;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1.2rem;
  padding: 0.5rem;
  transition: var(--transition);
  border-radius: 50%;
}

.add-parallel-step-button:hover {
  background-color: rgba(0, 123, 255, 0.1);
}

.add-parallel-step-button.active {
  background-color: #00796b;
  color: #fff;
}

.add-parallel-message {
  background-color: #fff3e0; /* Light orange background */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ff9800;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-parallel-message .cancel-button {
  background-color: transparent;
  border: none;
  color: #ff9800;
  cursor: pointer;
  font-weight: bold;
}

.add-parallel-message .cancel-button:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .workflow-modal {
    width: 95%;
    padding: 1.5rem;
  }

  .select-group {
    flex-direction: column;
  }

  .add-step-container .select-group {
    flex-direction: column;
  }

  .modal-buttons {
    flex-direction: column;
  }

  .primary-button,
  .secondary-button {
    width: 100%;
  }
}
